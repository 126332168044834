.text {
    /* display: flex; */
    justify-content: center; 
    align-items: center;
    margin: auto;
    font-size: 225%;
}

.easter_egg:hover span {
    display: none
  }
  
.easter_egg:hover:before {
    content: "General Kenobi!"
  }

.inner-container {
    font-size: 1.1rem;
    font-weight: 300;
}

.img {
    border-radius: 10%;
}